import { createTheme, responsiveFontSizes } from '@mui/material/styles';

export const Theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: '#a8866c',
      },
      secondary: {
        main: '#a8866c',
      },
    },
    typography: {
      fontFamily: ['Inter', 'Helvetica'].join(','),
    },
    breakpoints: {
      keys: ['xs', 'sm', 'md', 'lg', 'xl'],
      values: { xs: 0, sm: 680, md: 850, lg: 1200, xl: 1800 },
    },
  })
);
