import axios from 'axios';
import { Cart, PersonalizedImage, PosterImage, Product, Template } from 'canvas-common';
import { CartUpdateBody } from 'canvas-common';

const API = () => {
  const client = axios.create({
    baseURL: `https://api.${process.env.REACT_APP_STAGE}.canvaspaints.com`,
  });
  return {
    getProducts: async (): Promise<Product[]> => {
      const res = await client.get('/cart/product');
      return res.data;
    },
    getTemplates: async (): Promise<Template[]> => {
      const res = await client.get('/template');
      return res.data;
    },
    getAllKeywords: async (): Promise<string[]> => {
      const res = await client.get('/template/keywords');
      return res.data;
    },
    getAllImages: async (): Promise<Partial<PosterImage>[]> => {
      const res = await client.get('/template/images');
      return res.data;
    },
    getAnonCart: async (userId: string): Promise<Cart> => {
      const res = await client.get(`/cart/anonymous/${userId}`);
      return res.data;
    },
    getImage: async (pk: string): Promise<PosterImage> => {
      const res = await client.get(`/template/images/${pk}`);
      return res.data;
    },

    updateCart: async (body: CartUpdateBody): Promise<Cart> => {
      const res = await client.post(`/cart/anonymous/${body.cart.userUUID}`, body);
      return res.data;
    },
    deleteCart: async (userId: string): Promise<Cart> => {
      const res = await client.delete(`/cart/anonymous/${userId}`);
      return res.data;
    },
    getS3BucketUploadUrl: async (userId: string, uuid: string) => {
      const res = await client.get(`/render/upload/${userId}/${uuid}`);
      return res.data;
    },
    renderTemplate: async (body: any) => {
      const res = await client.post('/render', body);
      return res.data;
    },
    cropAndResize: async (body: PersonalizedImage) => {
      const res = await client.post('/render/crop', body);
      return res.data;
    },
    getPurchase: async (purchaseId: string) => {
      const res = await client.get(`/cart/purchase/${purchaseId}`);
      return res.data;
    },
    getOrders: async (token: string) => {
      const res = await client.get(`/order/${token}`, {
        withCredentials: true,
      });
      return res.data;
    },
    getOrderForPaymentIntent: async (paymentIntentId: string) => {
      const res = await client.get(`/order/pi/${paymentIntentId}`);
      return res.data;
    },
    createOrderLink: async (email: string) => {
      const res = await client.post('/order/link', { email });
      return res.data;
    },

    createShipment: async (body: any) => {
      const res = await client.post('/order/shipment', body);
      return res.data;
    },

    getOrdersToPrint: async () => {
      const res = await client.get('/order/print/list');
      return res.data;
    },
  };
};

export default API;
