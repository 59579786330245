import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { Header } from './Header';
import { Footer } from './Footer';
import { AllPostersView } from '../views/AllPostersView';
import { AboutView } from '../views/AboutView';
import { CreateView } from '../views/CreateView';
import { LoginView } from '../views/LoginView';
import { CartView } from '../views/CartView';
import { OrderView } from '../views/OrderView';
import { TemplateView } from '../views/TemplateView';
import { PrivateRoute } from './PrivateRoute';
import { HomeView } from '../views/HomeView';
import { BasePage } from '../views/BasePage';
import { PosterView } from '../views/PosterView';
import { CssBaseline } from '@mui/material';

const AppLayout = () => (
  <>
    <CssBaseline />
    <Header />
    <Outlet />
    <BasePage>
      <Footer />
    </BasePage>
  </>
);

export const Router = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route path="/home" element={<HomeView />} />
          <Route path="/sports_banners" element={<CreateView />} />
          <Route path="/posters" element={<AllPostersView />} />
          <Route path="/poster/:posterId" element={<PosterView />} />
          <Route path="/create" element={<CreateView />} />
          <Route path="/about" element={<AboutView />} />
          <Route path="/login" element={<PrivateRoute component={LoginView} />} />
          <Route path="/cart" element={<CartView />} />
          <Route path="/order" element={<OrderView />} />
          <Route path="/template/:templateId" element={<TemplateView />} />
          <Route path="/" element={<Navigate to="/home" replace />}></Route>
          <Route path="*" element={<Navigate to="/home" replace />}></Route>
        </Route>
      </Routes>
    </>
  );
};
