import { Box, Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const PosterDescription = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        mt={3}
        component={Stack}
        direction="column"
        spacing={2}
        justifyContent="center"
        sx={{ padding: '40px', minHeight: { xs: 300, lg: 600 } }}
      >
        <Typography
          variant="h4"
          sx={{
            fontFamily: 'Instrument Serif',
            textAlign: 'center',
          }}
        >
          Select from our curated catalog of images or upload your favorite photo to personalize
          your space.
        </Typography>

        <Box
          px={3}
          component="img"
          src="poster.jpg"
          alt="Custom poster prints to decorate your space."
        />

        <Typography
          variant="h4"
          sx={{
            fontFamily: 'Instrument Serif',
            textAlign: 'center',
          }}
        >
          Adhesive Posters
        </Typography>

        <Stack direction={'row'} justifyContent={'center'} spacing={2}>
          <Button variant="contained" color="secondary" onClick={() => navigate('/posters')}>
            Get Started
          </Button>
        </Stack>
      </Box>
    </>
  );
};
