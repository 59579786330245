import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';

export const CustomPosterCard = () => {
  return (
    <Grid key={'custom'} size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
      <Box justifyItems="center">
        <Box position={'relative'} sx={{ aspecRatio: '1/1' }}>
          <Box
            justifyItems={'center'}
            sx={{
              height: '100%',
              width: '100%',
              objectFit: 'cover',
            }}
            component="img"
            src={'custom.png'}
            alt="Custom poster prints to decorate your space."
          />
          <Box
            component="span"
            sx={{
              position: 'absolute',
              border: 'none',
              backgroundColor: 'white',
              left: 0,
              top: 0,
              minWidth: '100%',
              minHeight: '100%',
              opacity: 0,
              '&:hover': { opacity: '50%', cursor: 'zoom-in' },
            }}
            onClick={() => {
              console.log('CLICKED');
            }}
          ></Box>
        </Box>
      </Box>
    </Grid>
  );
};
