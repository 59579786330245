import Grid from '@mui/material/Grid2';
import { HeroTextBox } from '../home/HeroTextBox';
import { ImageGallery } from '../home/ImageGallery';
import { PosterDescription } from '../home/PosterDescription';
import { SportsBannerDescription } from '../home/SportsBannerDescription';
import { Box, Stack } from '@mui/material';

export const HomeView = () => {
  console.log(process.env.REACT_APP_CART_REDIRECT);
  return (
    <Stack direction="row">
      <Box sx={{ flexGrow: 1 }} />
      <Grid container sx={{ maxWidth: 1200 }}>
        <Grid size={{ xs: 12, lg: 5 }}>
          <HeroTextBox text="Transform your space into a personal masterpiece with our custom prints — where every wall tells your story!" />
        </Grid>
        <Grid size={{ xs: 12, lg: 7 }}>
          <ImageGallery />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <PosterDescription />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <SportsBannerDescription />
        </Grid>
      </Grid>
      <Box sx={{ flexGrow: 1 }} />
    </Stack>
  );
};
