import { Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Template } from 'canvas-common';

interface TemplateDisplayProps {
  template: Template;
  index: number;
}

export const TemplateDisplay = ({ template, index }: TemplateDisplayProps) => {
  const navigate = useNavigate();

  return (
    <>
      <Grid key={index} item xs={12} sm={4} md={3} lg={2}>
        <Card key={index} sx={{ width: '160px' }}>
          <CardContent style={{ justifyContent: 'center', display: 'flex' }}>
            <Stack>
              <Typography fontSize="12px" color="textSecondary" gutterBottom>
                {template.title}
              </Typography>
              <img
                // eslint-disable-next-line react/no-unknown-property
                fetchpriority="high"
                width="150px"
                height="375px"
                src={template.url}
                alt={template.title}
              />
              <Button
                onClick={() => navigate(`/template/${template.data}`)}
                variant="contained"
                sx={{ marginTop: '10px', marginLeft: '10px', marginRight: '10px' }}
                aria-label="Details for this banner template"
              >
                Details
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};
