import { ThemeProvider } from '@mui/material/styles';
import UserDataProvider from './context/UserData';
import ProductDataProvider from './context/ProductData';
import TemplateDataProvider from './context/TemplateData';
import SnackbarContextProvider from './context/SnackbarContext';
import { AuthProvider } from './context/AuthProvider';
import { UserContextProvider } from './context/UserContext';
import { Theme } from './styles/Theme';
import { Router } from './components/basic/Router';

export const App = () => {
  return (
    <AuthProvider>
      <UserContextProvider>
        <ProductDataProvider>
          <TemplateDataProvider>
            <UserDataProvider>
              <ThemeProvider theme={Theme}>
                <SnackbarContextProvider>
                  <Router />
                </SnackbarContextProvider>
              </ThemeProvider>
            </UserDataProvider>
          </TemplateDataProvider>
        </ProductDataProvider>
      </UserContextProvider>
    </AuthProvider>
  );
};
