import { Box, IconButton, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid2';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { UserData } from '../../context/UserData';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export const PosterCard = ({ image, numImages }: any) => {
  const { addFavorite, removeFavorite, favorites } = useContext(UserData);
  const navigate = useNavigate();

  const isFavorite = favorites.includes(image.pk);

  const sm = numImages < 2 ? 12 : 6;
  const md = numImages < 2 ? 12 : 4;
  const lg = numImages < 2 ? 12 : numImages < 5 ? 4 : 3;

  return (
    <Grid key={image.pk} size={{ xs: 12, sm, md, lg }}>
      <Box position={'relative'} sx={{ aspectRatio: '1/1' }}>
        <Box
          justifyItems={'center'}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          component="img"
          src={`https://templates.${process.env.REACT_APP_STAGE}.canvaspaints.com/images/${image.pk}_s.jpg`}
          alt="Custom poster prints to decorate your space."
        />
        <Box
          component="span"
          sx={{
            position: 'absolute',
            border: 'none',
            backgroundColor: 'white',
            left: 0,
            top: 0,
            minWidth: '100%',
            minHeight: '100%',
            opacity: 0,
            '&:hover': { opacity: '50%', cursor: 'zoom-in' },
          }}
          onClick={() => {
            navigate(`/poster/${image.pk}`);
          }}
        >
          <Tooltip title={isFavorite ? 'Remove from favorites' : 'Add to favorites'}>
            <IconButton
              sx={{
                position: 'absolute',
                color: 'red',
                left: 10,
                top: 10,
                opacity: 1,
                '&:hover': { opacity: '80%' },
              }}
              size="large"
              aria-label="favorite"
              onClick={(e) => {
                if (isFavorite) {
                  removeFavorite(image.pk);
                } else {
                  addFavorite(image.pk);
                }
                e.stopPropagation();
              }}
            >
              {isFavorite && <FavoriteIcon />}
              {!isFavorite && <FavoriteBorderOutlinedIcon />}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Grid>
  );
};
