export const DotSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 -960 960 960"
    width="24px"
    fill="inherit"
  >
    <path d="M480-480Zm0 280q-116 0-198-82t-82-198q0-116 82-198t198-82q116 0 198 82t82 198q0 116-82 198t-198 82Zm0-80q83 0 141.5-58.5T680-480q0-83-58.5-141.5T480-680q-83 0-141.5 58.5T280-480q0 83 58.5 141.5T480-280Z" />
  </svg>
);

export const DotFilledSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      d="M12,19c-1.9,0-3.6-0.7-4.9-2S5,13.9,5,12s0.7-3.6,2.1-5S10.1,5,12,5s3.6,0.7,5,2.1s2,3,2,5s-0.7,3.6-2,5
	S13.9,19,12,19z"
    />
  </svg>
);
