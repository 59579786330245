"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentIntent = void 0;
var PaymentIntent = /** @class */ (function () {
    function PaymentIntent(data) {
        Object.assign(this, data);
    }
    return PaymentIntent;
}());
exports.PaymentIntent = PaymentIntent;
