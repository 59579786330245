import { Box, Stack, Typography } from '@mui/material';

interface HeroTextBoxProps {
  text: string;
}

export const HeroTextBox = ({ text }: HeroTextBoxProps) => {
  return (
    <Stack direction="row">
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }} />

      <Stack
        direction="column"
        justifyContent="center"
        sx={{
          backgroundColor: 'secondary.main',
          padding: '20px',
          minHeight: { xs: 300, lg: 640 },
          maxWidth: { xs: 640, lg: 3000 },
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontFamily: 'Instrument Serif',
            textAlign: 'center',
            color: 'white',
          }}
        >
          {text}
        </Typography>
      </Stack>
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }} />
    </Stack>
  );
};
