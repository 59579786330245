import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { Product, Template } from 'canvas-common';
import { TemplateData } from '../../context/TemplateData';
import { PersonalizeDialog } from '../personalize/PersonalizeDialog';
import { ProductData } from '../../context/ProductData';
import { TemplateStructuredData } from '../basic/TemplateStructuredData';
import { TemplateCard } from '../cards/TemplateCard';

export const TemplateView = () => {
  const navigate = useNavigate();
  const [personalize, setPersonalized] = useState(false);
  const [template, setTemplate] = useState<Template | undefined>();
  const { getTemplate, templatesLoading } = useContext(TemplateData);

  const { templateId } = useParams();
  if (!templateId) navigate('/');

  useEffect(() => {
    const fetchTemplate = async () => {
      if (templatesLoading) return;

      const t: Template | undefined = getTemplate(templateId!);
      if (!t) navigate('/');

      setTemplate(t);
    };

    fetchTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templatesLoading]);

  const { getProduct, productsLoading } = useContext(ProductData);

  const handlePersonalizeClosed = () => {
    setPersonalized(false);
  };

  if (!template || productsLoading) {
    return <CircularProgress />;
  }

  if (personalize) {
    return <PersonalizeDialog template={template} onClose={handlePersonalizeClosed} />;
  }

  const product = getProduct('24_POLY') as Product;
  return (
    <>
      <TemplateStructuredData template={template} />
      <TemplateCard template={template} setPersonalized={setPersonalized} />
    </>
  );
};
