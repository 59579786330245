import { useContext, useState } from 'react';
import {
  AppBar,
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { BadgeProps } from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { UserData } from '../../context/UserData';
import { CloseButton } from './CloseButton';

const pages = ['Home', 'Posters', 'Sports Banners'];
const locations = [
  { path: '/', index: 0 },
  { path: '/home', index: 0 },
  { path: '/posters', index: 1 },
  { path: '/sports_banners', index: 2 },
];

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const Header = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const location = useLocation();
  const navigate = useNavigate();

  let menuIndex: number | undefined | false = locations.find(
    (loc) => loc.path === location.pathname
  )?.index;
  if (menuIndex === undefined) menuIndex = false;

  const { cart } = useContext(UserData);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  interface BasicTabsProps {
    menuItem: number | false;
  }
  const BasicTabs = ({ menuItem }: BasicTabsProps) => {
    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderColor: 'divider' }}>
          <Tabs value={menuItem} aria-label="menu tabs" sx={{ mb: '10px' }}>
            {pages.map((page, index) => {
              const theRef = `/${page.replace(' ', '_').toLowerCase()}`;
              return (
                <Tab
                  key={index}
                  label={<Typography variant="h6">{page}</Typography>}
                  component={Link}
                  to={theRef}
                  {...a11yProps(index)}
                />
              );
            })}
          </Tabs>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <AppBar position="sticky" color="inherit" sx={{ zIndex: '10', marginBottom: '30px' }}>
        <Box ml={3}>
          <Toolbar disableGutters>
            <Box
              component="img"
              mr={5}
              my={2}
              sx={{
                height: 57,
                width: 100,
                display: { xs: 'none', md: 'block' },
              }}
              alt="CanvasPaints"
              src="/logo.png"
            />
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page, index) => (
                  <MenuItem
                    key={page}
                    onClick={() => {
                      navigate(`/${page.replace(' ', '_').toLowerCase()}`);
                      handleCloseNavMenu();
                    }}
                  >
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              component="img"
              sx={{
                height: 38,
                width: 67,
                flexJustify: 'space-between',
                display: { xs: 'flex', md: 'none' },
              }}
              alt="CanvasPaints"
              src="/logo.png"
            />
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} />
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <BasicTabs menuItem={menuIndex} />
            </Box>

            <Box mr={3} sx={{ flexGrow: 0 }}>
              <Tooltip title="Show Cart">
                <IconButton
                  aria-label="cart"
                  onClick={() => {
                    navigate('/cart?step=0');
                  }}
                  sx={{ marginRight: '20px' }}
                >
                  <StyledBadge badgeContent={cart.cartItems!.length} color="primary">
                    <ShoppingCartIcon />
                  </StyledBadge>
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
    </>
  );
};
