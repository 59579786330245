import { useContext, useState } from 'react';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { TemplateDisplay } from '../personalize/TemplateDisplay';
import { TemplateData } from '../../context/TemplateData';

export const CreateView = () => {
  const [sport, setSport] = useState('');
  const [team, setTeam] = useState('');
  const [teams, setTeams] = useState<string[]>([]);
  const { templates } = useContext(TemplateData);

  const handleSportChange = (event: SelectChangeEvent) => {
    const curSport = event.target.value as string;
    setSport(curSport);
    setTeam('');
    const sportTeams = templates.filter((template) => template.sport === curSport);
    const curTeams = new Set<string>();
    sportTeams.forEach((sportTeam) => curTeams.add(sportTeam.team!));
    const sortedTeams: string[] = Array.from(curTeams).sort();
    setTeams(sortedTeams);
  };

  const handleTeamChange = (event: SelectChangeEvent) => {
    setTeam(event.target.value as string);
  };

  const sports = new Set<string>();
  templates.forEach((template) => {
    sports.add(template.sport!);
  });
  const sortedSports = Array.from(sports).sort();

  const filterTemplates = () => {
    let filtTemplates = [...templates];
    if (sport) filtTemplates = filtTemplates.filter((t) => t.sport === sport);
    if (team) filtTemplates = filtTemplates.filter((t) => t.team === team);
    return filtTemplates;
  };

  return (
    <Stack spacing="2" direction="row">
      <Box width="200px">
        <Box ml={2} mt={2}>
          <Typography variant="h6" align="left">
            Filter:
          </Typography>
        </Box>
        <FormControl fullWidth sx={{ ml: '10px', mt: '10px' }}>
          <InputLabel id="sport-select-label">Sport</InputLabel>
          <Select
            labelId="sport-select-label"
            id="sport-select"
            value={sport}
            label="Sport"
            onChange={handleSportChange}
          >
            {sortedSports.map((sp, index) => (
              <MenuItem key={index} value={sp}>
                <Typography>{sp}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {sport && (
          <FormControl fullWidth sx={{ m: '10px', mt: '20px' }}>
            <InputLabel id="team-select-label">Team</InputLabel>
            <Select
              labelId="team-select-label"
              id="team-select"
              value={team}
              label="Team"
              onChange={handleTeamChange}
            >
              {teams.map((tm, index) => (
                <MenuItem key={index} value={tm}>
                  <Typography>{tm}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2 }} sx={{ paddingLeft: '10px' }}>
        {filterTemplates().map((template, index) => {
          return <TemplateDisplay template={template} index={index} key={index} />;
        })}
      </Grid>
    </Stack>
  );
};
