import { Box, IconButton, Tooltip } from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
interface ClostButtonProps {
  onClose: () => void;
}

export const CloseButton = ({ onClose }: ClostButtonProps) => {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', pb: 1, ml: 6 }}>
        <Tooltip title="Go Back">
          <IconButton onClick={onClose} size="large" color="primary" aria-label="cancel">
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );
};
