import { Box, Paper, StepLabel, Step, Stepper } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { PaymentForm } from '../cart/PaymentForm';
import { CartDisplay } from '../cart/CartDisplay';
import { PaymentStatus } from '../cart/PaymentStatus';
import { AddressForm } from '../cart/AddressForm';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const steps = ['Shopping Cart', 'Shipping', 'Payment', 'Order Created'];

export const CartView = () => {
  const [searchParams] = useSearchParams();
  const [activeStep, setActiveStep] = useState(0);
  const [paymentIntentId, setPaymentIntentId] = useState<string | null>(null);

  const step = searchParams.get('step');
  const paymentIntent = searchParams.get('payment_intent');

  const navigate = useNavigate();

  useEffect(() => {
    if (step) {
      setActiveStep(0);
      navigate('/cart');
    }
    if (paymentIntent) {
      setActiveStep(3);
      setPaymentIntentId(paymentIntent);
      navigate('/cart');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper sx={{ margin: '12px', padding: '20px' }}>
      <Box sx={{ width: '100%' }}>
        {activeStep > 0 && (
          <Stepper activeStep={activeStep} orientation={small ? 'vertical' : 'horizontal'}>
            {steps.map((label) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        )}

        <>
          {activeStep === 0 && <CartDisplay handleNext={handleNext} />}
          {activeStep === 1 && <AddressForm handleBack={handleBack} handleNext={handleNext} />}
          {activeStep === 2 && <PaymentForm handleBack={handleBack} />}
          {activeStep === 3 && (
            <PaymentStatus paymentIntentId={paymentIntentId} handleBack={handleBack} />
          )}
        </>
      </Box>
    </Paper>
  );
};
