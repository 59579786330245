import Grid from '@mui/material/Grid2';
import { PosterCard } from '../posters/PosterCard';
import { Box, Stack } from '@mui/material';
import { KeywordPanel } from '../posters/KeywordPanel';
import { useContext, useEffect, useState } from 'react';
import { UserData } from '../../context/UserData';
import { CustomPosterCard } from '../posters/CustomPosterCard';
import { PosterImage } from 'canvas-common';
import API from '../../api/API';

export const AllPostersView = () => {
  const [images, setImages] = useState<Partial<PosterImage>[]>([]);
  const { favorites } = useContext(UserData);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [beImages, setBeImages] = useState<Partial<PosterImage>[]>([]);
  const apiClient = API();

  useEffect(() => {
    const loadImages = async () => {
      const images = await apiClient.getAllImages();
      setBeImages(images);
    };
    loadImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let newImages = [...beImages];
    if (keywords.includes('favorites')) {
      newImages = newImages.filter((i) => favorites.includes(i.pk!));
    }
    const otherKeywords = keywords.filter((w) => w !== 'favorites');
    if (otherKeywords.length > 0) {
      newImages = newImages.filter((i) => i.keywords?.some((kw) => otherKeywords.includes(kw)));
    }
    setImages(newImages);
  }, [favorites, keywords, beImages]);

  return (
    <>
      <KeywordPanel updateKeywords={(words) => setKeywords(words)} />

      <Stack direction={'row'} mx={3}>
        <Box sx={{ flexGrow: 1, display: 'flex' }} />

        <Box maxWidth={'1800px'}>
          <Grid container spacing={2}>
            {keywords.length === 0 && <CustomPosterCard />}
            {images.map((i) => (
              <PosterCard key={i.pk} image={i} numImages={images.length} />
            ))}
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, display: 'flex' }} />
      </Stack>
    </>
  );
};
