import { Box, Button, Typography } from '@mui/material';
import { Elements, AddressElement, LinkAuthenticationElement } from '@stripe/react-stripe-js';
import { useEffect, useMemo, useState, useContext } from 'react';
import { UserData } from '../../context/UserData';
import { getStripe } from '../../utils/getStripe';
import { CenteredProgress } from '../basic/CenteredProgress';

interface AddressFormProps {
  handleBack: () => void;
  handleNext: () => void;
}

export const AddressForm = ({ handleBack, handleNext }: AddressFormProps) => {
  const [addressComplete, setAddressComplete] = useState(false);
  const [emailComplete, setEmailComplete] = useState(false);
  const { cart, syncCartItems } = useContext(UserData);
  const [secret, setSecret] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string>('');
  const [address, setAddress] = useState<any>({});

  useEffect(() => {
    const createPayment = async () => {
      if (cart) {
        const newCart = await syncCartItems({
          cart,
          createOrUpdatePayment: true,
          calculateTax: false,
        });
        const { clientSecret } = newCart;
        setSecret(clientSecret);
      }
    };

    createPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = {
    clientSecret: useMemo(() => secret, [secret]),
  };

  const handleUpdate = () => {
    syncCartItems({
      cart: { ...cart, email, shippingAddress: address },
      createOrUpdatePayment: true,
      calculateTax: true,
    });
    handleNext();
  };

  return (
    <>
      {!secret && <CenteredProgress />}

      {secret && (
        <Elements options={options} stripe={getStripe()}>
          <Box>
            <Typography mt="20px" ml="12px" mb="12px" variant="h5" align="left">
              Shipping
            </Typography>
          </Box>
          <Box m="12px">
            <Typography align="left" mb="10px">
              All order status updates will be provided via e-mail. Ensure your e-mail address is
              correct.
            </Typography>
            <LinkAuthenticationElement
              onChange={(event) => {
                setEmail(event.value.email);
                setEmailComplete(event.complete);
              }}
            />

            <AddressElement
              onChange={(event) => {
                setAddress(event.value);
                setAddressComplete(event.complete);
              }}
              options={{
                mode: 'shipping',
                fields: { phone: 'always' },
                validation: { phone: { required: 'always' } },
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button variant="outlined" onClick={handleBack} sx={{ mr: 1 }}>
              Back To Cart
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button
              variant="outlined"
              onClick={handleUpdate}
              disabled={!addressComplete || !emailComplete}
            >
              Payment Info
            </Button>
          </Box>
        </Elements>
      )}
    </>
  );
};
