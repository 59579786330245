"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Cart = void 0;
var Cart = /** @class */ (function () {
    function Cart(data) {
        Object.assign(this, data);
    }
    return Cart;
}());
exports.Cart = Cart;
