import { Box, IconButton, Stack, Tooltip } from '@mui/material';

import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CenteredProgress } from '../basic/CenteredProgress';
import API from '../../api/API';
import { CartItem, PosterImage, Product } from 'canvas-common';
import { BorderColorSelector } from '../posters/BorderColorSelector';
import { CloseButton } from '../basic/CloseButton';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { v4 } from 'uuid';
import { UserData } from '../../context/UserData';
import { SnackbarContext } from '../../context/SnackbarContext';
import { ProductData } from '../../context/ProductData';

export const PosterView = () => {
  const [busy, setBusy] = useState<boolean>(true);
  const [poster, setPoster] = useState<PosterImage | null>(null);
  const navigate = useNavigate();
  const [color, setColor] = useState<[number, number, number] | null>(null);

  const { cart, syncCartItems } = useContext(UserData);
  const { successSnack } = useContext(SnackbarContext);
  const { getProduct }: any = useContext(ProductData);

  const { posterId } = useParams();
  if (!posterId) navigate('/');

  const apiClient = API();

  useEffect(() => {
    const loadPoster = async () => {
      if (!posterId) return;
      const poster = await apiClient.getImage(posterId);
      setPoster(poster);
      setBusy(false);
    };

    loadPoster();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posterId]);

  const setTheColor = (color: [number, number, number] | null) => {
    setColor(color);
  };

  const handleAddToCart = () => {
    const product: Product = getProduct('24_POLY');
    const cartItem: CartItem = {
      id: v4(),
      product,
      posterImage: { ...poster, ...(color && { borderColor: color }) },
      quantity: 1,
    };
    syncCartItems({
      cart: { ...cart, cartItems: [...cart.cartItems!, cartItem] },
      createOrUpdatePayment: false,
      calculateTax: false,
    });
    successSnack('Nice! The poster has been added to your shopping cart.');
    navigate(-1);
  };

  const rgbToHex = ([r, g, b]: [number, number, number]) => {
    const toHex = (c: number) => {
      const hex = c.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };

    return '#' + toHex(r) + toHex(g) + toHex(b);
  };

  const xs = color ? `20px solid ${rgbToHex(color)}` : 'none';
  const sm = color ? `40px solid ${rgbToHex(color)}` : 'none';

  return (
    <>
      {busy && <CenteredProgress />}
      {!busy && (
        <>
          <CloseButton onClose={() => navigate(-1)} />
          <Box display="flex" justifyContent="center" alignItems="center">
            <Stack direction="column" spacing={2}>
              <Box>
                <Box
                  maxWidth="min(90vw, 700px)"
                  maxHeight="700px"
                  component="img"
                  src={`https://templates.${process.env.REACT_APP_STAGE}.canvaspaints.com/images/${
                    poster!.pk
                  }_l.jpg`}
                  sx={{ border: { xs, sm } }}
                />
              </Box>

              <BorderColorSelector colorPalette={poster!.colorPalette!} setColor={setTheColor} />

              <Tooltip title="Add To Cart">
                <span>
                  <IconButton
                    onClick={handleAddToCart}
                    size="large"
                    color="primary"
                    aria-label="add to shopping cart"
                  >
                    <AddShoppingCartIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          </Box>
        </>
      )}
    </>
  );
};
