import { CircularProgress, Stack, Typography } from '@mui/material';

interface CenteredProgressProps {
  label?: string;
}

export const CenteredProgress = ({ label }: CenteredProgressProps) => {
  return (
    <div style={{ position: 'relative', height: '100vh' }}>
      <div
        style={{
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: `translate(-50%, -50%)`,
        }}
      >
        <Stack display="flex" justifyContent="center" alignItems="ceneter">
          <CircularProgress />
          {label && (
            <Typography mt={2} align="center">
              {label}
            </Typography>
          )}
        </Stack>
      </div>
    </div>
  );
};
