import { Box, Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const SportsBannerDescription = () => {
  const navigate = useNavigate();

  return (
    <Stack direction="row">
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }} />

      <Box
        mt={3}
        component={Stack}
        spacing={2}
        direction="column"
        justifyContent="center"
        sx={{ padding: '40px', minHeight: { xs: 300, lg: 600 } }}
      >
        <Stack direction="row" alignItems="center" spacing={4}>
          <Typography
            variant="h4"
            maxWidth="180px"
            sx={{
              fontFamily: 'Instrument Serif',
              textAlign: 'center',
            }}
          >
            Turn your young athlete into a superstar! Custom sports banners featuring pro team logos
            and your child's photos—inspiration for a lifetime!
          </Typography>

          <Box
            component="img"
            src="soccer.png"
            alt="Personalized sports banners"
            maxHeight={{ xs: '500px', lg: '700px' }}
          />
        </Stack>

        <Typography
          variant="h4"
          sx={{
            fontFamily: 'Instrument Serif',
            textAlign: 'center',
          }}
        >
          Adhesive Sports Banners
        </Typography>

        <Stack direction={'row'} justifyContent={'center'} spacing={2}>
          <Button variant="contained" color="secondary" onClick={() => navigate('/sports_banners')}>
            Get Started
          </Button>
        </Stack>
      </Box>

      <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }} />
    </Stack>
  );
};
