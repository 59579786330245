import { Box, FormControl, FormControlLabel, FormGroup, Stack, Switch } from '@mui/material';
import { useState } from 'react';

interface BorderColorSelectorProps {
  colorPalette: [number, number, number][];
  setColor: (color: [number, number, number] | null) => void;
}

const rgbToHex = ([r, g, b]: [number, number, number]) => {
  const toHex = (c: number) => {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  return '#' + toHex(r) + toHex(g) + toHex(b);
};

export const BorderColorSelector = ({ colorPalette, setColor }: BorderColorSelectorProps) => {
  const [selected, setSelected] = useState<number>(0);
  const [borderEnabled, setBorderEnabled] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBorderEnabled(event.target.checked);
    setColor(event.target.checked ? colorPalette[selected] : null);
  };

  const selectColor = (index: number) => {
    setSelected(index);
    setColor(colorPalette[index]);
  };

  return (
    <Box>
      <FormControl component="fieldset">
        <FormGroup aria-label="add a border" row>
          <FormControlLabel
            control={<Switch color="primary" onChange={handleChange} />}
            label="Add a border?"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <Stack direction="row" spacing={0.5}>
        {colorPalette.map((c, i) => {
          const color = rgbToHex(c);
          const size = borderEnabled && selected === i ? 31 : 40;
          const pos = borderEnabled && selected === i ? 5 : 0;
          return (
            <Box key={i} position="relative" width="40px" height="40px">
              <Box
                position="absolute"
                component="span"
                sx={{ bgcolor: color, width: size, height: size, top: pos, left: pos }}
                onClick={() => {
                  if (borderEnabled) selectColor(i);
                }}
              />

              {borderEnabled && selected === i && (
                <Box
                  sx={{
                    border: 4,
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '41px',
                    height: '41px',
                    borderColor: 'primary.main',
                  }}
                />
              )}
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};
