import { Box, Button, Input, Stack } from '@mui/material';
import CropIcon from '@mui/icons-material/Crop';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import ReactCrop, { centerCrop, makeAspectCrop, Crop, PercentCrop } from 'react-image-crop';
import { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { Rectangle } from 'canvas-common';

const styles = {
  hidden: { display: 'none' },
};

interface PhotoCropDialogProps {
  file: File;
  rect: Rectangle;
  origCrop: PercentCrop | undefined;
  handleCropComplete: (file: File, crop: PercentCrop | undefined, index: number) => void;
  handleCropCanceled: () => void;
  index: number;
}

const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
};

export const PhotoCropDialog = ({
  file,
  rect,
  origCrop,
  handleCropComplete,
  handleCropCanceled,
  index,
}: PhotoCropDialogProps) => {
  const [imgSrc, setImgSrc] = useState('');
  const [imgFile, setImgFile] = useState(file);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PercentCrop>();

  useEffect(() => {
    const reader = new FileReader();
    reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
    reader.readAsDataURL(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onImageLoad = (e: SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    const theCrop = origCrop || centerAspectCrop(width, height, rect.aspectRatio);
    setCrop(theCrop);
  };

  const updateImage = (event: ChangeEvent<HTMLInputElement>) => {
    const newFile: File | undefined = event.target.files?.[0];
    if (newFile) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
      reader.readAsDataURL(newFile);
      setImgFile(newFile);
    }
  };

  return (
    <Stack direction="column" display="flex" justifyContent="center" alignItems="center">
      <Box>
        {!!imgSrc && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => {
              setCrop(percentCrop);
            }}
            onComplete={(_, percentCrop) => {
              setCompletedCrop(percentCrop);
            }}
            aspect={rect.aspectRatio}
          >
            <Box
              component="img"
              width={{ xs: '300px', md: '800px' }}
              ref={imgRef}
              alt="Crop"
              src={imgSrc}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}
      </Box>

      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => handleCropComplete(imgFile, completedCrop, index)}
          startIcon={<CropIcon />}
        >
          Crop and Upload
        </Button>
        <Input
          sx={styles.hidden}
          id="update-file-button"
          onChange={(e) => updateImage(e as ChangeEvent<HTMLInputElement>)}
          type="file"
          inputProps={{ accept: 'image/jpeg, image/jpg' }}
        />
        <label htmlFor="update-file-button">
          <Button fullWidth variant="outlined" component="span" startIcon={<CloudUploadIcon />}>
            Change Photo...
          </Button>
        </label>
        <Button fullWidth variant="outlined" onClick={handleCropCanceled}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};
