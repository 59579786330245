import {
  Autocomplete,
  Badge,
  BadgeProps,
  Box,
  Chip,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useContext, useEffect, useRef, useState } from 'react';
import { UserData } from '../../context/UserData';
import { v4 } from 'uuid';
import API from '../../api/API';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

// const beKeywords = ['peaceful', 'yosemite', 'landscape', 'beach', 'evening'].sort();

interface KeywordPanelProps {
  updateKeywords: (keywords: string[]) => void;
}

export const KeywordPanel = ({ updateKeywords }: KeywordPanelProps) => {
  const { favorites } = useContext(UserData);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [keyword, setKeyword] = useState<string | null>(null);
  const apiClient = API();
  const beKeywords = useRef<string[]>([]);

  let autoKey = v4();

  useEffect(() => {
    const loadKeywords = async () => {
      beKeywords.current = await apiClient.getAllKeywords();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      autoKey = v4();
    };

    loadKeywords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearKeyword = (word: string) => {
    const newKeywords = [...keywords].filter((w) => w !== word);
    setKeywords(newKeywords);
    updateKeywords(newKeywords);
  };

  const addKeyword = (word: string) => {
    const lc = word.toLowerCase();
    if (!keywords.includes(lc)) {
      setKeywords([...keywords, lc]);
    }
    updateKeywords([...keywords, lc]);
  };

  return (
    <>
      <Stack direction={'row'} mx={{ xs: 1, sm: 3 }} mb={1}>
        <Box sx={{ flexGrow: 1, display: 'flex' }} />

        <Box mx={{ xs: 1, sm: 3 }}>
          <Tooltip title="Show Favorites">
            <IconButton
              aria-label="favorites"
              onClick={() => {
                addKeyword('favorites');
              }}
            >
              <StyledBadge badgeContent={favorites?.length || 0} color="primary">
                <FavoriteIcon />
              </StyledBadge>
            </IconButton>
          </Tooltip>
        </Box>

        <Autocomplete
          key={autoKey}
          fullWidth
          disablePortal
          value={keyword}
          options={beKeywords.current}
          onChange={(ev, value) => {
            if (value) addKeyword(value);
            setKeyword(null);
            autoKey = v4();
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label="Search By Keyword"
                slotProps={{
                  input: {
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      </>
                    ),
                  },
                }}
              />
            );
          }}
        />
        <Box sx={{ flexGrow: 1, display: 'flex' }} />
      </Stack>

      <Box ml={8} mb={4}>
        {keywords.length > 0 && (
          <Typography display="inline" sx={{ mr: 2 }}>
            Keywords:
          </Typography>
        )}
        {keywords.map((word) => {
          return <Chip key={word} label={word} onDelete={() => clearKeyword(word)} />;
        })}
      </Box>
    </>
  );
};
