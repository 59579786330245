import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useNavigate } from 'react-router-dom';
import { useInterval } from '../../hooks/useInterval';
import API from '../../api/API';
import { UserData } from '../../context/UserData';
import { SnackbarContext } from '../../context/SnackbarContext';

const apiClient = API();
let retries = 0;

interface PaymentStatusProps {
  paymentIntentId: string | null;
  handleBack: () => void;
}
export const PaymentStatus = ({ paymentIntentId, handleBack }: PaymentStatusProps) => {
  const [order, setOrder] = useState<any | null>(null);
  const [status, setStatus] = useState<string>('pending');
  const [orderStatus, setOrderStatus] = useState<string>('pending');
  const [delay, setDelay] = useState<number>(1000);
  const { clearCart, cart } = useContext(UserData);
  const { errorSnack, successSnack } = useContext(SnackbarContext);

  const navigate = useNavigate();

  useInterval(async () => {
    if (paymentIntentId) {
      const paymentIntent = await apiClient.getPurchase(paymentIntentId);
      if (paymentIntent.status !== 'pending') {
        setStatus(paymentIntent.status);
        if (paymentIntent.status !== 'succeeded') {
          errorSnack('Unable to process payment.');
          clearCart();
          setDelay(0);
        } else {
          successSnack('Payment processed.');
          setOrderStatus('creating');
        }
      }

      if (orderStatus === 'creating') {
        let orderNumber;
        try {
          orderNumber = await apiClient.getOrderForPaymentIntent(paymentIntentId);
          setOrder({ orderNumber });
          clearCart();
          setDelay(0);
          successSnack('Order created!');
        } catch (e) {}
      } else {
        retries += 1;
        if (retries > 10) {
          setOrder({ orderNumber: 'FAILED' });
          setDelay(0);
        }
      }
    } else {
      setDelay(0);
    }
  }, delay);

  return (
    <>
      <Box mt="30px">
        <Typography mt="20px" ml="12px" mb="12px" variant="h5" align="left">
          Payment Status: {status.toUpperCase()}
        </Typography>
        {status !== 'failed' && !order && (
          <Box>
            <Typography>
              {status === 'pending' ? 'Processing Payment...' : 'Creating Order...'}
            </Typography>
            <CircularProgress />
          </Box>
        )}
        {order && (
          <Box>
            <Stack direction="row" ml="12px" mt="20px" mb="12px">
              <VerifiedIcon fontSize="medium" />
              <Box sx={{ width: '8px' }} />
              <Typography>Thank you for your order!</Typography>
            </Stack>
            <Typography align="left" ml="12px">
              Order Number: {order.orderNumber}
            </Typography>
            <Typography align="left" ml="12px" mt="12px">
              An email with a link to track your order will be sent to: {cart.email}
            </Typography>
            <Box mt="20px">
              <Button onClick={() => navigate('/')} variant="contained">
                Home
              </Button>
            </Box>
          </Box>
        )}
        {status === 'failed' && (
          <Box>
            <Typography>
              Payment Failed. Please correct payment information and try again
            </Typography>
            <Box mt="20px">
              <Button onClick={handleBack} variant="contained">
                Correct It
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
