import { AppBar, Box, Stack, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <>
      <AppBar position="fixed" color="secondary" sx={{ top: 'auto', bottom: 0, minHeight: '70px' }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ flexGrow: 1 }} />

          <Stack direction={'row'} spacing={6}>
            <Typography>
              <Link to="/about" style={{ color: 'white', textDecoration: 'none' }}>
                About
              </Link>
            </Typography>
            <Typography>
              <Link to="/about" style={{ color: 'white', textDecoration: 'none' }}>
                Privacy
              </Link>
            </Typography>
            <Typography>
              <Link to="/about" style={{ color: 'white', textDecoration: 'none' }}>
                Terms of Use
              </Link>
            </Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
        </Toolbar>
      </AppBar>
    </>
  );
};
