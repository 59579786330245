import { Box, Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { CloseButton } from '../basic/CloseButton';
import { useNavigate } from 'react-router-dom';
import { Template } from 'canvas-common';

interface TemplateCardProps {
  template: Template;
  setPersonalized: (personalized: boolean) => void;
}

export const TemplateCard = ({ template, setPersonalized }: TemplateCardProps) => {
  const navigate = useNavigate();

  return (
    <>
      <CloseButton onClose={() => navigate(-1)} />
      <Grid container spacing={1}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Stack alignItems="center" justifyContent="center">
            <Box component="img" height={500} src={template.url} alt={template.title} />
          </Stack>
        </Grid>

        <Grid size={{ xs: 12, md: 6 }}>
          <Stack alignItems="center" justifyContent="center">
            <Box mb={1}>
              <Typography fontSize={20} align="left" color="textSecondary">
                {template.title}
              </Typography>
              <Typography fontSize={18} align="left" color="textSecondary">
                Sport: <b>{template.sport}</b>
              </Typography>
              <Typography fontSize={18} align="left" color="textSecondary">
                Team: <b>{template.team}</b>
              </Typography>
              <Typography fontSize={18} align="left" color="textSecondary" gutterBottom>
                Number of Photos: {template.images.length}
              </Typography>
            </Box>

            <Button
              onClick={() => setPersonalized(true)}
              variant="contained"
              sx={{ width: 200, mt: 1, ml: 1, mr: 1 }}
              aria-label="Personalize this banner template"
            >
              Personalize
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

/* <Divider />

            <Box mt={3}>
              <Typography variant={'h5'} align="left" color="textSecondary">
                Product
              </Typography>
              <Typography fontSize={20} align="left" color="textSecondary">
                {product.name}
              </Typography>
              <Typography fontSize={18} align="left" color="textSecondary">
                {product.description}
              </Typography>
              <Typography fontSize={18} align="left" color="textSecondary">
                Price: {currency(product.price).format()}
              </Typography>
            </Box> */
